import { useState, useEffect, useRef, Fragment, useCallback } from "react";
import { motion, AnimatePresence, useAnimation, useCycle } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SiteContext } from "../../Contexts/SiteContext";

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

const loopTimeout = 5000;

function CarouselSlider({ children, loop = true, settings = null }) {
    const [[page, direction], setPage] = useState([0, 0]);
    const [slideOffset, setSlideOffset] = useState();
    const [carouselHeight, setCarouselHeight] = useState();
    const [isAnimating, setAnimationState] = useState(false);
    const [paginateTimeoutRef, setPaginateTimeoutRef] = useState(-1);

    const contentIndex = wrap(0, children.length, page);

    const carouselComponent = useRef(null);
    const carouselComponentWindow = useRef(null);

    const paginate = useCallback((newDirection) => {
        setAnimationState(true);
        setPage([page + newDirection, newDirection]);
        
        clearTimeout(paginateTimeoutRef);
        setPaginateTimeoutRef(
            setTimeout(
                () => {                
                    setAnimationState(false);
                },
                1500
            )
        );
    }, [paginateTimeoutRef]);

    useEffect(() => {
        return () => {
            clearTimeout(paginateTimeoutRef);
        }
    }, [paginateTimeoutRef]);

    const variants = {
        enter: (direction) => {
            return {
                x: direction > 0 ? slideOffset : -slideOffset,
                opacity: 1,
                zIndex: 0,
            };
        },
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1
        },
        exit: (direction) => {
            return {
                x: direction < 0 ? slideOffset : -slideOffset,
                zIndex: 1,
                opacity: 1
            };
        },
    };

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (!isAnimating){
                if (page + 1 >= children.length){
                    paginate(-children.length + 1)
                } else if (page + 1 < children.length){
                    paginate(1)
                } else {
                    paginate(-1)
                }
            }
        }, loopTimeout);
        return () => clearTimeout(timeOut);
    }, [isAnimating]);

    useEffect(() => {
        let carouselComponentSlide = carouselComponent.current.querySelector('.carousel-component__window-item');
        let casourselWidth = carouselComponentSlide.getBoundingClientRect().width;
        setSlideOffset(casourselWidth);
        function handleResize() {
            let carouselComponentSlide = carouselComponent.current.querySelector('.carousel-component__window-item');
            let casourselWidth = carouselComponentSlide.getBoundingClientRect().width;
            setSlideOffset(casourselWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [slideOffset]);

    useEffect(() => {
        let carouselComponentSlide = carouselComponent.current.querySelector('.carousel-component__window-item');
        let timeout = -1;
        
        if ( carouselHeight === undefined) {            
            timeout = setTimeout(
                () => {
                    setCarouselHeight(carouselComponentSlide.getBoundingClientRect().height);
                },
                250
            );
        } else if (!(carouselHeight > 0)){
            setCarouselHeight(carouselComponentSlide.getBoundingClientRect().height);
        } else {
            timeout = setTimeout(
                () => {
                    setCarouselHeight(carouselComponentSlide.getBoundingClientRect().height);
                },
                250
            );
        }

        function handleResizeHeight() {
            let carouselComponentSlide = carouselComponent.current.querySelector('.carousel-component__window-item');
            setCarouselHeight(carouselComponentSlide.getBoundingClientRect().height);
        }

        window.addEventListener('resize', handleResizeHeight)

        return () => {
            window.removeEventListener("resize", handleResizeHeight);
            clearTimeout(timeout);
        }

    }, [carouselHeight]);

    return (
        <SiteContext.Consumer>
            {({ deviceType }) => {
                return (
                    <div ref={carouselComponent}
                        className="carousel-component"
                        data-layout="contained"
                        data-device={deviceType}
                    >
                        <div ref={carouselComponentWindow}
                            className="carousel-component__window"
                            style={{ height: carouselHeight }}
                        >
                            <AnimatePresence exitBeforeEnter={false} initial={false} custom={direction}>
                                <motion.div
                                    className={'carousel-component__window-item'}
                                    key={page}
                                    custom={direction}
                                    variants={variants}
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    transition={{
                                        default: { duration: 0.2 },
                                        delay: 0.2,
                                        x: {
                                            type: "spring",
                                            stiffness: 200,
                                            damping: 25,
                                        },
                                        opacity: 0.2,

                                    }}
                                    drag={children.length > 1 && "x"}
                                    dragConstraints={{ left: 0, right: 0 }}
                                    dragElastic={0.25}
                                    onDragEnd={(e, { offset, velocity }) => {
                                        const swipe = swipePower(offset.x, velocity.x);
                                        if (swipe < -swipeConfidenceThreshold) {
                                            if (page + 1 < children.length) {
                                                paginate(1);
                                            }
                                        } else if (swipe > swipeConfidenceThreshold) {
                                            if (page - 1 >= 0) {
                                                paginate(-1);
                                            }
                                        }
                                    }}
                                >
                                    {children[contentIndex]}
                                </motion.div>
                            </AnimatePresence>
                        </div>
                        { children.length > 1 &&
                            <Fragment>
                                <div className="carousel-component__nav">
                                    { page - 1 >= 0 &&
                                        <motion.button
                                            type="button"
                                            aria-label="Previous"
                                            className="chevron previous"
                                            style={{
                                                top: `${carouselHeight / 2}px`
                                            }}
                                            onClick={() => paginate(-1)}
                                            whileHover={{
                                                scale: 1.1,
                                                transition: { duration: 0.2 },
                                            }}
                                            whileTap={{
                                                scale: 0.8,
                                            }}
                                        >
                                            <div className="navItem valign-wrapper">
                                                <FontAwesomeIcon
                                                    icon={["fal", "angle-left"]}
                                                    size="3x"
                                                />
                                            </div>
                                        </motion.button>
                                    }
                                    { page + 1 < children.length &&
                                        <motion.button
                                            type="button"                                            
                                            aria-label="Next"
                                            className="chevron next"
                                            style={{
                                                top: `${carouselHeight / 2}px`
                                            }}
                                            onClick={() => paginate(1)}
                                            whileHover={{
                                                scale: 1.1,
                                                transition: { duration: 0.2 },
                                            }}
                                            whileTap={{
                                                scale: 0.8,
                                            }}
                                        >
                                            <div className="navItem valign-wrapper">
                                                <FontAwesomeIcon
                                                    icon={["fal", "angle-right"]}
                                                    size="3x"
                                                />
                                            </div>
                                        </motion.button>
                                    }
                                </div>
                            </Fragment>
                        }
                        {   settings && 
                            settings.bullets.enable && 
                            children.length > 1 &&
                            <div
                                className="carousel-component__bullets"
                                style={{
                                    backgroundColor: `rgba(51,51,51,${ settings.bullets.background ? .5 : 0 })`
                                }}
                            >
                                <div
                                    className="bullets"
                                    data-bullets-position={settings.bullets.align}>
                                    {children.map((bullet, index) => (
                                        <span
                                            key={index}
                                            className={contentIndex == index ? "active" : ""}
                                            onClick={(() => {
                                                setPage([index, index - page]);
                                            })}>
                                        </span>)
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                );
            }}
        </SiteContext.Consumer>
    );
};

export default CarouselSlider;